const switchBaseModal = () => {
  // const $window = $(window);
  const $sidebar = $(".js-sidebar");

  const sidebarClose = () => {
    if ($sidebar.hasClass("is-visible")) {
      $sidebar.removeClass("is-visible");
      $(".js-switch-siderbar").removeClass("is-active");
    }
  };

  // sidebar
  $(".js-switch-siderbar").on("tap", function(e) {
    e.stopPropagation();
    $sidebar.toggleClass("is-visible");
    $(this).toggleClass("is-active");
  });

  // search modal
  $(".js-switch-search").on("click", e => {
    e.stopPropagation();
    $(".js-search").toggleClass("is-visible");
    sidebarClose();
  });

  // signin modal
  $(".js-switch-sign").on("tap", e => {
    e.preventDefault();
    $(".js-signin-modal").toggleClass("is-visible");
    sidebarClose();
  });

  // forget modal
  $(".js-forget-mask").on("tap", e => {
    e.stopPropagation();
    $(".js-forget-modal").removeClass("is-visible");
  });
};

export default switchBaseModal;
