import Swiper from "swiper";

export default () => {
  let index = 0;
  new Swiper(".swiper-container", {
    slidesPerView: "auto",
    spaceBetween: 14,
    centeredSlides: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  });

  let tabIndex =
    (window.location.search && window.location.search.slice(-2) - 0) || 0;
  if (tabIndex > 3) {
    $("#scroll-tabs").scrollLeft(68 * tabIndex);
  }

  $("#next-tab").on("tap", () => {
    index += 1;
    $("#scroll-tabs").scrollLeft(68 * index);
  });

  $(".solution-summary").each(i => {
    const solutionDetailBtnDom = $(".solution-item")
      .eq(i)
      .find(".solution-detail-btn");
    const summaryDom = $(".solution-item")
      .eq(i)
      .find(".solution-summary");
    if ($(summaryDom).height() > 58) {
      $(summaryDom).addClass("u-text-limit--two");
    } else {
      $(solutionDetailBtnDom).css("display", "none");
    }
  });

  $(".solution-detail-btn").on("tap", e => {
    const parentDom = $(e.target).parent();
    const detailBtnDom = parentDom.find(".solution-detail-btn");
    const summaryDom = parentDom.find(".solution-summary");

    $(summaryDom).removeClass("u-text-limit--two");
    $(detailBtnDom).css("display", "none");
  });

  $(".apply").on("tap", () => {
    $(".hint-modal").css("display", "block");
    $(".hint-backdrop").css("display", "block");
    trackContactSyncedEvent();
  });

  $(".hint-backdrop").on("tap", () => {
    $(".hint-modal").css("display", "none");
    $(".hint-backdrop").css("display", "none");
  });

  $(".js-open-modal").each((i, dom) => {
    $(dom)[0].addEventListener("tap", () => {
      trackReviewMoreSolutionEvent($(dom).data("scenario"));
    });
  });

  $(".signup").on("click", () => {
    trackSignupEvent();
  });

  var eventTrack = (...args) => {
    if (!window.gtag) {
      return;
    }
    // eslint-disable-next-line no-undef
    gtag(...args);
  };

  var trackReviewMoreSolutionEvent = name => {
    eventTrack("event", "review_more_solution", {
      event_category: name,
    });
  };

  var trackContactSyncedEvent = () => {
    eventTrack("event", "contact_synced_in_solution", {});
  };

  var trackSignupEvent = () => {
    eventTrack("event", "signup_in_institution", {});
  };
};
