import { useSelect2WithoutAjax } from "./tools";

const reg = {
  username: /^[a-zA-Z0-9_]{6,20}$/,
  password: /^\S{6,20}$/,
};

export default () => {
  useSelect2WithoutAjax(".js-category-select2", "100%");

  const $filed = $(".js-signin-filed");
  const $pasword = $(".js-form-password");
  const $submitBtn = $(".js-form-btn");
  let inputValidate = [];

  $filed.on("input", function() {
    const { type } = this.dataset;
    if (!type) return;

    $(this.nextElementSibling).toggleClass(
      "is-error",
      !reg[type].test(this.value)
    );
    $submitBtn.attr("disabled", !reg[type].test(this.value));
  });

  $(".js-password-confirm").on("input", function() {
    if ($pasword.val() !== this.value) {
      $(this.nextElementSibling)
        .addClass("is-error")
        .html("两次输入密码不同");
      $submitBtn.attr("disabled", true);
    } else {
      $(this.nextElementSibling)
        .removeClass("is-error")
        .html("请再次填写密码");
      $submitBtn.removeAttr("disabled");
    }
  });

  const inputValidateEvent = ($allInput, type) => {
    $allInput.each(() => {
      inputValidate.push(false);
    });
    $allInput.on("input", function() {
      inputValidate[$allInput.index(this)] = this.value !== "";
      if (type === "login") {
        if ($allInput.index(this) === 0) {
          inputValidate[$allInput.index(this)] = reg["username"].test(
            this.value
          );
        }
        if ($allInput.index(this) === 2) {
          if ($pasword.val() !== this.value) {
            $(this.nextElementSibling)
              .addClass("is-error")
              .html("两次输入密码不同");
            inputValidate[$allInput.index(this)] = false;
          } else {
            $(this.nextElementSibling)
              .removeClass("is-error")
              .html("请再次填写密码");
            inputValidate[$allInput.index(this)] = true;
          }
        }
      }
      let isDisabledBtn = false;
      inputValidate.forEach(isValidate => {
        if (!isValidate) {
          isDisabledBtn = true;
        }
      });

      if (isDisabledBtn) {
        $submitBtn.attr("disabled", true);
        $submitBtn.addClass("disabled-submit-btn");
        return;
      }
      $submitBtn.removeAttr("disabled");
      $submitBtn.removeClass("disabled-submit-btn");
    });
  };

  inputValidateEvent($(".js-enter-input"));

  $("#enter-institution-next-step").on("click", function() {
    $("#enter-institution").css("display", "none");
    $("#login-info").css("display", "block");
    inputValidate = [];
    $submitBtn.attr("disabled", true);
    $submitBtn.addClass("disabled-submit-btn");
    inputValidateEvent($(".js-login-input"), "login");
  });

  $("#login-info-next-step").on("click", function() {
    $("#login-info").css("display", "none");
    $("#contact-info").css("display", "block");
    inputValidate = [];
    $submitBtn.attr("disabled", true);
    $submitBtn.addClass("disabled-submit-btn");
    inputValidateEvent($(".js-contact-input"));
  });
};
