import "./styles/app.scss";
import "jquery";
import "zepto.touch";
import turbolinks from "turbolinks";
import RailsUJS from "rails-ujs";
import "select2";
import "select2/dist/js/i18n/zh-CN";
import "bootstrap/js/modal";

import myAlert from "./components/alert";
import header from "./components/header";
import switchBaseModal from "./components/switch_base_modal";
import signin from "./components/signin";
import signup from "./components/signup";
import ajaxFeedback from "./components/ajax_feedback";
import init from "./components/init.js";
import collection from "./components/collection.js";

const runPage = (condition, cb) => {
  if (condition) cb();
};

const mobile = () => {
  RailsUJS.start();
  turbolinks.start();

  header();
  ajaxFeedback();
  switchBaseModal();

  document.addEventListener("turbolinks:before-cache", () => {
    $(".js-select2, .js-aliase-select2").remove();
  });

  document.addEventListener("turbolinks:load", () => {
    const $body = $("body");
    const pageId = $body.attr("id");

    myAlert();

    runPage(pageId === "accounts-new", signup);
    runPage(pageId === "sessions-new", signin);
    runPage(pageId === "home-index", init);
    runPage(pageId === "collections-index", collection);
    runPage(pageId === "collections-show", collection);

    $("body").on("contextmenu", "a[data-remote='true']", function(e) {
      e.preventDefault();
    });

    $("body").on("click", "span[data-remote='true']", function(e) {
      const href = $(e.target).data("href");
      $.ajax(href);
    });
  });

  document.addEventListener(
    "touchstart",
    function(event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    },
    {
      passive: false, // 关闭被动监听
    }
  );

  var lastTouchEnd = 0;
  document.addEventListener(
    "touchend",
    function(event) {
      var now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );
};

export default mobile;
